import { getCookie, setCookie } from 'cookies-next';

export const COOKIES_KEY = 'sword_cookies_acceptance';
export const COOKIES_PATH = '/';
export const COOKIES_DOMAIN = process.env.NEXT_PUBLIC_COOKIES_DOMAIN;

export function setConsentCookie(value) {
  const expireDate = new Date();
  expireDate.setMonth(expireDate.getMonth() + 6);
  setCookie(COOKIES_KEY, value, {
    path: COOKIES_PATH,
    domain: COOKIES_DOMAIN,
    expires: expireDate,
  });
}

export function getConsentCookie() {
  return getCookie(COOKIES_KEY);
}

/**
 * Get specific permission consent
 *
 * @param {string} permission
 * - analytics
 * - marketing
 * - preferences
 *
 * @returns {boolean}
 */
export function hasConsent(permission) {
  const consent = getConsentCookie();

  try {
    const parsedConsent = JSON.parse(consent);

    return parsedConsent === true || parsedConsent?.[permission];
  } catch {
    return false;
  }
}
