'use client';

import { useEffect } from 'react';

import { identifyTracking, PERMISSION } from '@/utils/identify';
import { setConsentCookie } from '@/utils/cookie-consent';

const GRANTED_VALUE = 'granted';

function hasPermission(value) {
  return value === GRANTED_VALUE;
}

function handleAcceptAll() {
  identifyTracking({ permission: PERMISSION.FUNCTIONAL });
  setConsentCookie(true);
}

function handleRejectAll() {
  identifyTracking({ permission: PERMISSION.NECESSARY });
  setConsentCookie(false);
}

function handleAcceptSome(preferences) {
  identifyTracking({
    permission: {
      ...(preferences.analytics ? { [PERMISSION.ANALYTICS]: true } : {}),
      ...(preferences.marketing ? { [PERMISSION.TARGETING]: true } : {}),
      ...(preferences.preferences ? { [PERMISSION.PERFORMANCE]: true } : {}),
    },
  });
  setConsentCookie(JSON.stringify(preferences));
}

export default function CookieBanner() {
  useEffect(() => {
    function setConsentPreferences(preferences) {
      const config = {
        analytics: preferences.analytics_storage,
        marketing: preferences.ad_storage,
        preferences: preferences.personalization_storage,
      };
      const acceptAll = !Object.values(config).some((value) => !hasPermission(value));

      if (acceptAll) {
        handleAcceptAll();

        return;
      }

      const rejecttAll = !Object.values(config).some((value) => hasPermission(value));

      if (rejecttAll) {
        handleRejectAll();

        return;
      }

      handleAcceptSome({
        analytics: hasPermission(preferences.analytics_storage),
        marketing: hasPermission(preferences.ad_storage),
        preferences: hasPermission(preferences.personalization_storage),
      });
    }

    /*
     * Slight hack to catch GTM consent event on load and on change
     */
    window.dataLayer =
      window.dataLayer ??
      new Proxy([], {
        set: (obj, prop, value) => {
          if (prop !== 'length' && value?.value?.event === 'enzuzo_consent_update') {
            const pushEvent = new CustomEvent('sword_consent_update', {
              detail: window.enzuzoGtmConsentObj,
            });

            window.dispatchEvent(pushEvent);
          }

          return Reflect.set(obj, prop, value);
        },
      });

    /*
     * Enzuzo Callbacks
     */
    window.__enzuzo = window.__enzuzo ?? {};
    window.__enzuzo.callbacks = {
      acceptAll: () => {
        handleAcceptAll();
      },
      acceptSelected: (preferences) => {
        handleAcceptSome(preferences);
      },
      decline: () => {
        handleRejectAll();
      },
    };

    window.addEventListener('sword_consent_update', (event) => setConsentPreferences(event.detail));

    () => {
      window.removeEventListener('sword_consent_update', (event) =>
        setConsentPreferences(event.detail),
      );
    };
  }, []);

  return null;
}
