import Script from 'next/script';

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
const isProd = process.env.NEXT_PUBLIC_ENV === 'production';

export function pageview(url) {
  if (!window.dataLayer) return null;

  window.dataLayer.push({
    event: 'pageview',
    page: url,
  });
}

export function GTMScript({ nonce }) {
  if (!GTM_ID) return null;

  return (
    <>
      <Script
        nonce={nonce}
        id="gtm-script"
        dangerouslySetInnerHTML={{
          __html: isProd
            ? `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
          n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GTM_ID}');`
            : `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=sxUyzVHxECRCioMqYTpp1Q&gtm_preview=env-104&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID}');`,
        }}
      />
      <noscript>
        <iframe
          src={
            isProd
              ? `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`
              : `https://www.googletagmanager.com/ns.html?id=${GTM_ID}&gtm_auth=sxUyzVHxECRCioMqYTpp1Q&gtm_preview=env-104&gtm_cookies_win=x"`
          }
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  );
}

export { default as GTMPageViewTracker } from './GTMPageViewTracker';
