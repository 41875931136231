'use client';

import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';

function PageLoader() {
  return (
    <ProgressBar
      height="4px"
      color="var(--ui-colors-primary-default)"
      options={{ showSpinner: false }}
    />
  );
}

export default PageLoader;
