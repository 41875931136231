import { identify } from '@/lib/segment';

export function identifyDemoRequested({
  firstName,
  lastName,
  email,
  company,
  jobTitle,
  phone,
  formSlug,
}) {
  identify({
    first_name: firstName,
    last_name: lastName,
    email: email.toLowerCase(),
    client_name: company,
    job_title: jobTitle,
    phone_number: phone,
    product: formSlug,
  });
}

export function identifyEvent({
  firstName,
  lastName,
  email,
  company,
  jobTitle,
  phone,
  formSlug,
  eventType,
}) {
  identify({
    first_name: firstName,
    last_name: lastName,
    email: email.toLowerCase(),
    client_name: company,
    job_title: jobTitle,
    company: company,
    phone: phone,
    form_lug: formSlug,
    event_type: eventType,
  });
}

export function identifyJob({ firstName, lastName, email, company, phone, formSlug }) {
  identify({
    first_name: firstName,
    last_name: lastName,
    email: email.toLowerCase(),
    client_name: company,
    company: company,
    phone: phone,
    form_lug: formSlug,
  });
}

export const PERMISSION = {
  NECESSARY: 'necessary',
  FUNCTIONAL: 'functional',
  PERFORMANCE: 'performance',
  TARGETING: 'targeting',
  ANALYTICS: 'analytics',
};

export function identifyTracking({ permission }) {
  identify({
    permission,
  });
}
