'use client';

import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';

import { pageview } from './index';

export default function GTMPageViewTracker() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (!pathname) return;

    pageview(searchParams ? `${pathname}?${searchParams.toString()}` : pathname);
  }, [pathname, searchParams]);

  return null;
}
